:root {
    --sidebar-space: 300px;
    --page-content-width: calc(100vw - var(--sidebar-space));

    --main-color: rgba(0, 255, 225, 0.5);
    --main-color_flat: rgba(6, 146, 146, 0.9);
}

*{
    -ms-overflow-style: none;
}
::-webkit-scrollbar {
    display: none;
}

{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.webpage-links {
    color: #151414;
}
.flat-ui-colors .label-default {
    background-color: #95a5a6;
}
.flat-ui-colors .label-default[href]:hover,
.flat-ui-colors .label-default[href]:focus {
    background-color: #7f8c8d;
}
.flat-ui-colors .label-primary {
    background-color: rgb(1, 152, 152);
}
.flat-ui-colors .label-primary[href]:hover,
.flat-ui-colors .label-primary[href]:focus {
    background-color: rgb(4, 103, 103);
}

/* Vertical Time Line*/
/* CUSTOM LINE COLOR */
/* The line */
.vertical-timeline.vertical-timeline::before {
    background: rgb(181, 181, 181);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
    border: 2rem;
}

.vertical-timeline-element-date {
    color: rgb(0, 0, 0);
    padding: 40px 40px 60px;
    box-sizing: border-box;
    border-radius: 0.8rem;
}

.vertical-timeline.
/* Icon container's border */
.vertical-timeline.vertical-timeline-custom-line .vertical-timeline-element--work .vertical-timeline-element-icon {
    box-shadow: 0 0 0 4px #1976d2, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
}

.vertical-timeline.vertical-timeline-custom-line .vertical-timeline-element--education .vertical-timeline-element-icon {
    box-shadow: 0 0 0 4px #c2185b, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
}

.scroll-down {
    position: absolute;
    top: 90%;
    left: 50%;
    right: 50%;
    transform: translate(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;

}

.mouse {
    width: 1.25rem;
    height: 2.5rem;
    border: 1px solid #ffffff;
    border-radius: 1rem;
    margin-bottom: .25rem;

    display: flex;
}

.mouse span {
    width: .5rem;
    height: .5rem;
    background-color: #ffffff;
    display: block;
    border-radius: 50%;
    margin: auto;
    animation: move-wheel 1s linear infinite;
}

.arrow span {
    display: block;
    width: .5rem;
    height: .5rem;
    border: 1px solid transparent;
    border-right-color: #ffffff;
    border-bottom-color: #ffffff;
    transform: rotate(45deg);
    animation: arrow-down 1s alternate infinite;
}

@keyframes move-wheel {
    0%{
        opacity: 0;
        transform: translateY(.5rem);
    }
    100%{
        opacity: 1;
        transform: translateY(-.5rem);
    }

}

@keyframes arrow-down {
    0%{
        opacity: 0;
    }
    25%{
        opacity: .25;
    }
    50%{
        opacity: .5;
    }
    75%{
        opacity: 0.75;
    }
    100%{
        opacity: 1;
    }

}

.wrapper {
    /*background-image: url(../public/images/sidebar_mountain.jpeg);*/
}

#outter {
    width: 100vw;
    height: 100vh;
    position: relative;
}

/*div container for background image*/
#image {
    position: fixed;
    max-width: 50%;
    height: auto;
}

.bg-image {
    width: 100vw;
    height: 100vh;
    object-fit: cover;

}

#child{
    float: right;
    min-height: 100vh;
    width: 100vw;
}

#contentOutter {
    position: absolute;
}

#content {
    margin: 0 auto;
    width: 900px;
}


p.wrapper{
    position: relative;
}

ul.sidebar {
    list-style-type: none;
    text-align: center;
    margin: auto;
}

div.sidebar_logo {
    width: var(--sidebar-space);
    height: 200px;
}

img.sidebar_logo{
    width: auto;
    height: 70%;
    margin-top: 20%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    filter: drop-shadow(5px 5px 5px #666666);
}


/* The side navigation menu */
.sidebar {
    margin: 0;
    padding: 0;
    width: var(--sidebar-space);
    background-color: var(--main-color);
    position: fixed;
    height: 100%;
    overflow: auto;
    /*background-image: url(../public/images/sidebar_mountain.jpeg);*/
    text-align: center;
    float: left;
    box-shadow: 3px 0px 5px rgba(0, 0, 0, 0.3);
    z-index: 1000;

}

/* Sidebar links */
.sidebar a {
    display: block;
    color: black;
    padding: 16px;
    text-decoration: none;
}

/* Active/current link */
.sidebar a.active {
    background-color: #4fdadd;
    color: white;
}

/* Links on mouse-over */
.sidebar a:hover:not(.active) {
    transform: scale(1.5);
}

div.intro_box {

    background: #e7eef1;
    padding: 0.1rem 2rem;
    box-sizing: border-box;
    border-radius: 2px;
    box-shadow: 0 12px 0 -6px var(--main-color);

    position: relative;
    right: 20%;
    top: 35vh;

    width: auto;
    height: auto;
    float: right;

    text-align: left;
}

div.chapter_box {
    background: #ffffff;
    padding-right: 0px;
    box-sizing: border-box;
    padding-top: 2%;
    padding-bottom: 2%;
}

div.project {
    background-color: #ffffff;
    padding: 40px 40px 60px;
    box-sizing: border-box;
    width: 36rem;
    height: 55rem;
    position: relative;
    display: inline-block;
    margin: 3rem 1rem;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.5);
    border-radius: 1rem;
}

div.project_box {
    text-align: center;
    width: var(--page-content-width);
}

p.project_header {
    alignment: center;
    text-align: center;
    color: #4a4a4a;
    font-size: 1.75rem;
}

p.project_description {
    text-align: justify;
}

div.project h2 {
    text-align: center;
}

div.project h4 {
    text-align: center;
}

span.project_src {
    display: inline-block;
    margin: 0.3rem 0.2rem;
}

a.project_link {
    display: inline-block;
    margin: 0.3rem 0.2rem;
}

div.project_link {
    text-align: center;
}

project_links_group {
}


/* Links on mouse-over */
.project:hover:not(.active) {
    transform: scale(1.006);
}

div.intro_chapter {
    box-sizing: content-box;

    position: relative;
    width: var(--page-content-width);
    min-height: 100vh;
    left: var(--sidebar-space);
}

h1.intro_header {
    color: #535353;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
    text-align: right;
}

span.intro_header_name {
    color: var(--main-color_flat);
    font-weight: bold;
}

h2.intro_header {
    color: #646464;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
    text-align: right;
    margin-top: -10px;
}

div.chapter {
    background: #ffffff;
    background-color: #ffffff;
    box-sizing: content-box;

    position: relative;
    width: var(--page-content-width);
    min-height: 100vh;
    left: var(--sidebar-space);
}

.chapter h1 {
    text-align: center;
}

div.place_holder {
    box-sizing: content-box;

    position: relative;
    width: var(--page-content-width);
    height: 1.5rem;

    float: right;
    backdrop-filter: blur(6px);
}

/* Page content. The value of the margin-left property should match the value of the sidebar's width property */
div.content {
    margin-left: 200px;
    padding: 1px 16px;
    height: 1000px;
    float:left;
}


/* On screens that are less than 700px wide, make the sidebar into a topbar */
@media screen and (max-width: 840px) {

    #child{
        float: left;
        position: relative;
    }

    .bg-image {
        width: auto;
        height: 100vh;
    }

    .sidebar {
        width: 100vw;
        height: auto;
        position: fixed;
        z-index: 1000;
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
    }
    .sidebar a {float: left;}
    div.content {margin-left: 0;}

    div.chapter {
        background: #ffffff;
        background-color: #ffffff;
        box-sizing: content-box;

        position: relative;
        width: 100vw;
        min-height: 100vh;
        left: 0px;
    }

    div.sidebar_logo {
        display: none;
    }

    div.intro_chapter {
        box-sizing: content-box;

        position: relative;
        width: 100vw;
        min-height: 100vh;
        left: 0px;
    }
    h1.intro_header {
        font-size: 2.25rem;
    }

    h2.intro_header {
        font-size: 2rem;
    }

    div.chapter_box {
        padding-left: 5%;
        padding-right: 5%;
    }

    div.project {
        width: 99%;
        height: auto;

        padding-left: 4%;
        padding-right: 4%;
    }

    div.project_box {
        margin: auto;
        width: 70%;


    }

    div.intro_box {
        margin: auto;
        width: 65%;
    }
}

/* On screens that are less than 430px, display the bar vertically, instead of horizontally */
@media screen and (max-width: 430px) {

    .bg-image {
        width: auto;
        height: 100vh;
    }

    /*.sidebar a {
        text-align: center;
        float: none;
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
        display: none;
    }*/




    h1.intro_header {
        font-size: 2rem;
    }

    h2.intro_header {
        font-size: 1.85rem;
    }

    .sidebar {
        position: relative;
    }

}

